import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash'
import {apiDataToEvent, apiDataToEventObj} from '../utils/utils';
import {clearEvent, get_current_event, get_last_user, loadEvent, updateEvent} from '../store/Event';
import EventForm from '../forms/EventForm';
import Spinner from './Spinner';
import {
    addEmploeePhoto,
    clearEmploeesState,
    clearEmploeeState,
    loadEmploee,
    loadEmploeesList
} from "../store/Employees";
import {getFormValues, formValueSelector} from "redux-form";
import {clearEventReport, loadEventReport} from "../store/Events";
import {omitUndefinedAndNullFields} from "../utils/objects";
import { ROLES } from '../roles';

const Event = ({ loadEvent, updateEvent, isUserPending, user_from_conflog, clearEvent, event, closeModal, isListPending, employees, get_last_user_in_conflog, loadEvents, reload,  filtersList, user, addEmploeePhoto, loadEmploee, employee, isEmploeePending, clearEmploee}) => {
   
    const [firstLoad, setFirstLoad] = useState(true);
    const [activeUser, setActiveUser] = useState(0);
    const [isRoleEqual, setisRoleEqual] = useState(false)

    useEffect(() => {
        clearEmploee();
        if (activeUser !== 0) {
            loadEmploee(activeUser);
        }
    }, [activeUser])

    useEffect(() => {
        if (event) {
            const confLog = get(event, 'confirmation_log', [])
      
            if (confLog.length) {
                const lastConfLog = confLog.at(-1)
                get_last_user_in_conflog(lastConfLog.user_id)
            }
        }
    
    }, [event])

    useEffect(() => {
        return () => {
            setActiveUser(0)
            // clearEvent()
           }
    }, [])

    useEffect(() => {
       if (user_from_conflog) {
        const rolesFromLastUser = get(user_from_conflog, 'roles', [])
        const userRoles = get(user, 'roles', [])
        if (userRoles.includes(ROLES.HEAD) || userRoles.includes(ROLES.ADMIN)) {
            setisRoleEqual(true)
            return
        } else {
            if (user.role.includes(ROLES.OPERATOR)) {
                if (!rolesFromLastUser.includes(ROLES.HEAD) && !rolesFromLastUser.includes(ROLES.ADMIN)) {
                    setisRoleEqual(true)
                    return
                }
            }
        }
        setisRoleEqual(false)
       }

       return () => {
        setisRoleEqual(false)
       }
    },[user_from_conflog])

    const save = (val) => {
        updateEvent(event.id, val.violation, 1, val.person_id || 0);
        if (val.add_person) {
            addEmploeePhoto(val.person_id, val.values.photo_person, event.id);
        }
        setTimeout(()=> {
            reload();
            // loadEvents(page, sizePerPage, defaultQuery);
        }, 600);
        closeModal();
    };

    const onChangePerson = useCallback( (val) => {
        
        if (val.value !== activeUser) {
            setFirstLoad(false);
            setActiveUser(val.value)
        }
    },[activeUser]);
    
    if (!event?.id || isListPending) {
        return <Spinner/>
    }

    const onSubmit = (data) => {
        saveEvent(data)
    };

    const saveEvent = (val, values2) => {
        save(val);
    };

    return <EventForm
        onSubmit={onSubmit}
        event={apiDataToEventObj(event)}
        employees={employees}
        employee={employee}
        onChangePerson={onChangePerson}
        isEmploeePending={isEmploeePending}
        user={user}
        isRoleEqual={isRoleEqual}
    />
};

export default connect(
    state => ({
        event: state.event.current_event,
        user_from_conflog: state.event.lastUser,
        isUserPending: state.event.isUserPending,
        user: state.user,
        employee: state.employees.current,
        isListPending: state.employees.isListPending || state.event.isPending,
        isEmploeePending: state.employees.isUserPending,
    }),
    dispatch => ({
        loadEvents: (pageNumber, pageSize, query) => {
            dispatch(clearEventReport());
            dispatch(loadEventReport(query, pageNumber, pageSize));
        },
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },
        get_last_user_in_conflog: (id) => {
            dispatch(get_last_user(id));
        },
        clearEvent: () => {
            dispatch(clearEvent());
        },
        updateEvent: (eventId, hasViolation, userId, personId) => {
            dispatch(updateEvent(eventId, hasViolation, userId, personId));
        },
        addEmploeePhoto: (id, data, eventId) => {
            dispatch(addEmploeePhoto(id, data, eventId))
        },
        loadEmploee: (id) => {
            dispatch(loadEmploee(id));
        },
        clearEmploee: () => {
            dispatch(clearEmploeeState());
        },
    }),
)(Event);
