import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {textFilter} from 'react-bootstrap-table2-filter';

import {clearUsersState, clearUserState, deleteUser, loadUser, loadUsersList} from '../store/Users';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import UserModal from '../components/UserModal';
// import {loadSingleUserNotificationSettings, loadUserNotificationSettings} from '../store/UsersNotificationSettings';
import withDataErrorIndication from '../components/withDataErrorIndication';

const dateFormatter = ({date}) => {
    if (date) {
        // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // return new Date(date * 1000).toLocaleString('ru-RU', {timeZone});

        return new Date(date * 1000).toLocaleString('ru-RU');
    }
};

const UsersManagement = ({users = [], user, actions, isListPending}) => {
    const {
        loadUsersList,
        loadUser,
        deleteUser,
        clearUser
    } = actions;

    useEffect(() => {
        loadUsersList();
    }, []);

    const [shouldShowModal, setShowModal] = useState(false);

    useEffect(() => {
        // if (user && user.id) {
        //     setShowModal(true);
        //
        // }
    }, [user]);

    const Columns = () => [{
        text: 'Фамилия пользователя',
        dataField: 'fio',
        headerStyle: () => ({width: '430px'}),
        editable: false,
        filter: textFilter({
            placeholder: ' ',
        }),
    }, {
        text: 'Логин',
        dataField: 'username',
        headerStyle: () => ({width: '170px'}),
        editable: false,
        filter: textFilter({
            placeholder: ' ',
        }),
    }, {
        text: 'Телефон',
        dataField: 'phone_number',
        headerStyle: () => ({width: '150px'}),
        editable: false,
    }, {
        text: 'Мобильный',
        dataField: 'phone_number_additional',
        headerStyle: () => ({width: '150px'}),
        editable: false,
    }, {
        text: 'Электронная почта',
        dataField: 'email',
        headerStyle: () => ({width: '150px'}),
        editable: false,
    }, {
        text: 'Последний вход',
        dataField: 'last_login_at',
        headerStyle: () => ({width: '150px'}),
        formatter: (date) => dateFormatter({ date }),
        editable: false,
    }, {
        text: 'Блокирован?',
        dataField: 'blocked',
        headerStyle: () => ({width: '100px'}),
        formatter: (blocked) => blocked ? 'Да' : 'Нет',
        editable: false,
    }];

    document.title = 'Управление пользователями';

    return (
        <>
            <GeneralObjectsTable
                objects={users}
                getColumns={Columns}
                isPending={isListPending}
                isEditable={false}
                actions={{
                    update: () => {
                    },
                    create: () => {
                        setShowModal(true);
                    },
                    purge: deleteUser,
                    reload: loadUsersList
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadUser(row.id);
                        setShowModal(true);
                    }
                }}
            />
            {shouldShowModal &&
            <UserModal
                onCancel={() => {
                    setShowModal(false);
                    clearUser();
                }}
                onClose={() => {
                    setShowModal(false);
                    clearUser();
                }}
            />}
        </>
    )
};

export default connect(
    state => ({
        users: state.users.list,
        isListPending: state.users.isListPending,
        isError: state.users.isListError,
        user: state.users.current,
        cameras: state.camera.list || [],
    }),
    dispatch => ({
        actions: {
            loadUsersList: (accountType) => {
                dispatch(clearUsersState());
                dispatch(loadUsersList(accountType));
            },
            clearUser: () => {
                dispatch(clearUserState());
            },
            loadUser: (id) => {
                dispatch(clearUserState());
                dispatch(loadUser(id));
            },
            deleteUser: (id) => {
                dispatch(deleteUser(id));
            },
        }
    }),
)(withDataErrorIndication(UsersManagement));

