import React from 'react';

import {getDateNow} from '../utils/time';
import EventsPage from '../tables/EventsPage';
import {DEFAULT_INTERVAL_HOURS} from '../utils/constants';

const Events = (props) => {
    // console.log(props.params?.eventId)
    document.title = 'Все детектированные события';

    return (
        <EventsPage
            eventId={props.params?.eventId}
            defaultQuery={{
                dateBegin: getDateNow(-DEFAULT_INTERVAL_HOURS),
                dateEnd: getDateNow(),
            }}
            isViolationsOnly={false}
        />
    );
}

export default Events;
