import React from 'react';

import {getDateNow} from '../utils/time';
import EventsPage from '../tables/EventsPage';
import {DEFAULT_INTERVAL_HOURS, VIOLATION, VIOLATION_SETTINGS} from '../utils/constants';

const Violations = (props) => {
    document.title = 'Реестр нарушений';

    return (
        <EventsPage
            eventId={props.params?.eventId}
            defaultQuery={{
                dateBegin: getDateNow(-DEFAULT_INTERVAL_HOURS),
                dateEnd: getDateNow(),
                hasViolation: VIOLATION_SETTINGS.HAS_VIOLATION,
            }}
            isViolationsOnly
        />
    );
}

export default Violations;
