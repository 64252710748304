import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {RadioField} from '../inputs';

const CameraPermissionsForm = ({handleSubmit, formValues, handleCancel, forms, pristine, reset, submitting, dispatch, userId, isEditMode, initialValues, modPpes}) => {
    const [modPpesNew, setModPpesNew] = useState([]);
    const [newStatus, setNewStatus] = useState(0);

    useEffect(() => {
        setModPpesNew([]);
    }, []);

    useEffect(() => {
        setModPpesNew(modPpes);
        setNewStatus(newStatus + 1);
    }, [modPpes]);

    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';

    return (
        <form id={newStatus} onSubmit={handleSubmit} >
            <fieldset disabled={!isEditMode}>
                <Legend title="События">
                    <div className="overflow-div">
                        {modPpesNew.map((ppe,i) => (
                            <div key={i} className="viol-types-detect">
                                <label>{ppe.label}</label>
                                <div className="viol-types-detect-answers">
                                    <Field name={`${ppe.name}`} id={`${ppe.name}`} component={RadioField} custom="11111" value={ppe.value} initialValues={ppe.value}/>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    if (!state.camera.current?.id) {
        return {
            initialValues: {},
        };
    }
    return {
        initialValues: {
            ...state.camera.current,
        },
        formValues: getFormValues('CameraPermissionsForm')(state) || {},
        cameraId: state.camera.current.id,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'CameraPermissionsForm',
        enableReinitialize: true,
    })(CameraPermissionsForm)
);
