import {ROUTES} from './router/routes';

export const ROLES = {
    OPERATOR: 'operator',
    HEAD: 'head',
    ADMIN_SAFETY: 'admin_safety',
    // ANNOTATOR: 'annotator',
    // ANNOTATION_MANAGER: 'annotation_manager',
    ML: 'ml',
    INFORMATION_MANAGER: 'information_manager',
    ADMIN: 'AUTH_ROLE_ADMIN',
}

export const ROLE_NAMES = {
    OPERATOR: 'Оператор',
    HEAD: 'Руководитель',
    ADMIN_SAFETY: 'Администратор модуля идентификации нарушений ТБ',
    // ANNOTATION_MANAGER: 'Менеджер по разметке',
    // ANNOTATOR: 'Разметчик',
    ML: 'Специалист по машинному обучению',
    INFORMATION_MANAGER: 'Менеджер НСИ',
    ADMIN: 'Системный администратор',
}

export const PAGES_AVAILABLE = {
    [ROLES.OPERATOR]: [
        ROUTES.CAMERAS, ROUTES.CAMERA,  ROUTES.VIOLATIONS, ROUTES.VIOLATION, ROUTES.EVENTS, ROUTES.EVENT,
        ROUTES.FRAMES,ROUTES.FRAME, ROUTES.EMPLOYEES,ROUTES.EMPLOYEE, ROUTES.NOTIFICATIONS, ROUTES.NOTIFICATION, ROUTES.VIDEO_RECORDER_UP_VIDEO
    ],
    [ROLES.HEAD]: [
        ROUTES.CAMERAS, ROUTES.CAMERA, ROUTES.EVENTS,ROUTES.EVENT, ROUTES.EMPLOYEES, ROUTES.EMPLOYEE, ROUTES.REPORT_CAMERA,
        ROUTES.REPORT_PERIOD, ROUTES.REPORT_SCORES, ROUTES.REPORT_CONFIRMED, ROUTES.REPORT_USER, ROUTES.STATISTIC,
        ROUTES.NOTIFICATIONS, ROUTES.NOTIFICATION,
    ],
    [ROLES.ML]: [
        ROUTES.TENSORBOARD,
    ],
    // [ROLES.ML]: [
    //     ROUTES.SETS, ROUTES.TENSORBOARD, ROUTES.QUALITY,
    // ],
    // [ROLES.ANNOTATION_MANAGER]: [
    //     ROUTES.SOURCES,
    // ],
    // [ROLES.ANNOTATOR]: [
    //     ROUTES.ANNOTATION,
    // ],
    // [ROLES.ADMIN_SAFETY]: [
    //     ROUTES.DECISION_MATRIX, ROUTES.ZONES, ROUTES.CATEGORIES, ROUTES.MODELS,
    // ],
    [ROLES.ADMIN_SAFETY]: [
        ROUTES.DECISION_MATRIX, ROUTES.ROOM_WORK, ROUTES.WORK_PERMIT, ROUTES.SWITCHING_FORM, ROUTES.CUPBOARD_OUR_MANAGEMENT, ROUTES.CUPBOARD_NOT_OUR_MANAGEMENT, ROUTES.CUPBOARD_LAYOUT, ROUTES.REJECTED_EVENTS, ROUTES.ROOM_NOT_OUR, ROUTES.ROOM_NOT_OUR_MANAGEMENT,
    ],
    [ROLES.INFORMATION_MANAGER]: [
        ROUTES.BLOCKS_MANAGEMENT, ROUTES.ROOMS_MANAGEMENT, ROUTES.CAMERAS_MANAGEMENT, ROUTES.THERMAL_CAMERAS_MANAGEMENT,
        ROUTES.WORKWEAR, ROUTES.PPE, ROUTES.VIDEO_RECORDER_MANAGEMENT, ROUTES.VIDEO_RECORDER_USERS_LIST, ROUTES.NOTIFICATION_DEVICES, ROUTES.NOTIFICATION_DEVICES_MAPPING
    ],
    [ROLES.ADMIN]: [
        ROUTES.USERS_MANAGEMENT, ROUTES.EMPLOYEES_MANAGEMENT, ROUTES.SUBDIVISION_MANAGEMENT, ROUTES.CLEAN_SETTINGS, ROUTES.LOGS, ROUTES.MONITORING_SYSTEM, ROUTES.MONITORING_SERVER
    ]
}
