import React from 'react';
import videojs from 'video.js';
import 'videojs-errors';
import 'video.js/dist/video-js.css';

videojs.options.hls.overrideNative = true;
videojs.options.html5.nativeAudioTracks = false;
videojs.options.html5.nativeVideoTracks = false;

window.videojs = videojs;

class VideoPlayerHls extends React.Component {
    retryCount = 0;

    componentDidMount() {
        const config = {
            html5: {
                nativeAudioTracks: false,
                nativeVideoTracks: false,
                hls: {
                    debug: true,
                    overrideNative: true
                }
            }
        };

        this.initializePlayer(config);
    }

    initializePlayer = (config) => {
        this.player = videojs(this.videoNode, config, () => {
            console.log('Player initialized', this);

            try {
                this.setPlayerSource();

                // Обработчик ошибок плеера
                this.player.on('error', () => {
                    const error = this.player.error();
                    console.log("Player encountered an error:", error);

                    if (error && error.code === 4) {
                        this.retryLoad();
                    }
                });

                // Если поток отключается, пытаемся переподключиться
                this.player.on('pause', () => {
                    if (!this.player.ended() && this.retryCount < 5) {
                        this.retryLoad();
                    }
                });
            } catch (e) {
                console.log('Error initializing player>', e);
            }
        });
    };

    setPlayerSource = () => {
        this.player.src({
            src: this.props.src,
            type: 'application/x-mpegURL',
            withCredentials: true
        });
        this.player.play().catch(error => console.log("Play initiation error:", error));
    };

    retryLoad = () => {
        const maxRetries = 5;
        const retryInterval = 3000;

        if (this.retryCount < maxRetries) {
            this.retryCount++;
            console.log(`Retrying load attempt #${this.retryCount}`);

            setTimeout(() => {
                if (this.player && this.props.src) {
                    this.setPlayerSource(); // Перезагружаем видео источник без сброса плеера
                    this.player.play().catch(error => console.log("Retry play error:", error));
                }
            }, retryInterval);
        } else {
            console.log('Max retry attempts reached, cannot reload video');
        }
    };

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    render() {
        return (
            <div className="player-area">
                <div data-vjs-player>
                    <video
                        ref={node => this.videoNode = node}
                        autoPlay
                        controls
                        width="100%"
                        height="100%"
                        className="video-js vjs-default-skin vjs-big-play-centered"
                        data-setup='{"fluid": true}'
                    />
                </div>
            </div>
        );
    }
}

export default VideoPlayerHls;
