import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {getDateNow} from '../utils/time';
import {omitUndefinedAndNullFields} from '../utils/objects';
import {apiDataToEvent, apiDataToEventObj} from '../utils/utils';
import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from '../utils/constants';
import {clearEventReport, loadEventReport} from '../store/Events';
import {loadObjectsObservationList} from '../store/ObjectObservation';
import FramesFilter from '../forms/FramesFilter';
import Spinner from '../components/Spinner';
import Modal from '../components/Modal';
import Event from '../components/Event';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {clearEmploeesState, clearEmploeeState, loadEmploee, loadEmploeesList} from "../store/Employees";
import {userFio} from "../utils/userUtils";
import EmploeeFilter from "../forms/EmploeeFilter";
import MuiPagination from "../components/MuiPagination";
import { get_current_event } from '../store/Event';
import { useRouter } from 'react-router5';

const defaultQuery = {
    dateBegin: getDateNow(-DEFAULT_INTERVAL_HOURS),
    dateEnd: getDateNow(),
    withFaces: 1,
    withRecognizedFaces: 0
};

const EventFrame = ({event, emploeeInfo, clickHandler}) => {
    return (
        <div className="images-item emploee" onClick={clickHandler}>
            <div className="img-wrapper">
                <img src={event.photo_person} alt=""/>
            </div>
            <div className="text-wrapper">
                <p className="violation">{emploeeInfo ? userFio(emploeeInfo) : 'Лицо не распознано'}</p>
                <p className="violation">{event.type}</p>
                <p className="date">{event.date}</p>
            </div>
        </div>
    )
}

const Employees = ({events, params, current_event, get_current_event,clearEvent,  objects, loadEvents, clearEvents, loadObjects, isPendingEvents, loadEmployeesList, employees, eventsTotal}) => {
    const [page, setPage] = useState(START_PAGE);
    const [event, setEvent] = useState(null);
    const route = useRouter()

    const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
    const [filtersList, setfiltersList] = useState(null);

    useEffect(() => {
        loadEvents(START_PAGE, defaultQuery, PAGE_SIZE);
        loadObjects();
        loadEmployeesList();
    }, []);

    useEffect(() => {
        if (params?.eventId) {
            get_current_event(params?.eventId)
        }
        return () => {
            clearEvent()
        }
    },[params])

    useEffect(() => {
        if (current_event?.id) {
            setEvent(current_event)
        }
    },[current_event])

    useEffect(() => {
        reloadQuery();
    }, [filtersList, sizePerPage, page]);

    const FilteredData = Object.entries(events || []).filter(item => item[1].primary_objects.length !== 0).filter(item => item[1].primary_objects[0].secondary_objects.length !== 0).filter(item => item[1].object_observation !== null);
    const apiEvents = FilteredData.map((event) => apiDataToEventObj(event[1], objects.list));

    const reloadQuery = () => {
        // if (filtersList !== null) {
            const filtersQuery = {
                ...(filtersList?.power_unit && {power_unit: filtersList.power_unit}),
                ...(filtersList?.room && {object_id: filtersList.room}),
                ...(filtersList?.from && {dateBegin: filtersList.from}),
                ...(filtersList?.to && {dateEnd: filtersList.to}),
                ...(filtersList?.with_recognized_faces && {withRecognizedFaces: filtersList.with_recognized_faces}),
                ...(filtersList?.confirmation_status && {hasViolation: filtersList.confirmation_status}),
            };
            loadEvents(page, {
                ...defaultQuery,
                ...omitUndefinedAndNullFields(filtersQuery)
            }, sizePerPage);
        // }
    }

    const onTableChange = (type, page) => {
        setPage(page);
    };

    useEffect(() => {
        reloadQuery();
    }, [filtersList, sizePerPage]);

    const reload = () => {
        reloadQuery();
    };

    const onFilterChange = (values) => {
        clearEvents();
        setSizePerPage(sizePerPage);
        setfiltersList(values);
    }

    document.title = 'Детектированные лица';

    if (!objects) {
        return <Spinner/>
    }

    let tableRef;
    return (
        <>
            <div className="images-list">
                <EmploeeFilter
                    onSubmit={data => onFilterChange(data)}
                    reset={() => reload()}
                />
                <div className="images-list">
                    {(isPendingEvents || !apiEvents) ?
                        <Spinner/>
                        : <>
                        {apiEvents
                            // .map(eventData => apiDataToEventObj(eventData))
                            .map(event =>
                                <EventFrame
                                    key={event.id}
                                    event={event}
                                    emploeeInfo={employees.find(item => item.id === event.person)}
                                    clickHandler={() => {
                                        route.navigate('EMPLOYEE', { eventId: event.id })
                                        setEvent(event)
                                    }}
                                />
                            )}
                            {apiEvents && !apiEvents.length && !isPendingEvents && 'Данные отсутствуют'}
                            <MuiPagination
                                count={Math.ceil(eventsTotal / sizePerPage)}
                                page={page}
                                rowsPerPage={sizePerPage}
                                handleChangePagination={(type, page) => {
                                    // if (type === 'filter') {
                                    //     const object = options.filters.room;
                                    //     setObjectId(object ? parseInt(object.filterVal) : null);
                                    //     const unit = options.filters.power_unit;
                                    //     setUnitId(unit ? parseInt(unit.filterVal) : null);
                                    // }
                                    // onTableChange(type, options);
                                    onTableChange(type, page);
                                }}
                                handleChangeRowsPerPage={(event) => {
                                    setSizePerPage(event.target.value);
                                    setPage(1);
                                }}
                            />
                        </>
                        }
                </div>
            </div>
            {event &&
            <Modal
                title={`Нарушение № ${event.id}`}
                className="big_modal"
                isOpen
                onClose={() => {
                    route.navigate('EMPLOYEES')
                    clearEvent()
                    setEvent(null);
                }}>
                <Event
                    eventId={event.id}
                    reload={() => reload()}
                    employees={employees}
                />
            </Modal>}
        </>
    );
}

export default connect(
    state => ({
        user: state.user,
        event: state.event.event,
        events: state.events.list?.events,
        employees: state.employees.list,
        current_event: state.event?.current_event || {},
        current_event_isPending: state.event?.current_event_isPending || false,
        isPendingEvents: state.events.isPending || state.employees.isListPending,
        isError: state.events.isError,
        objects: state.object,
        eventsTotal: state.events.list?.total_events,
    }),
    dispatch => ({
        loadEvents: (pageNumber, query, pageSize) => {
            dispatch(clearEventReport());
            dispatch(loadEventReport(query, pageNumber, pageSize));
        },
        clearEvents: () => {
            dispatch(clearEventReport());
        },
        clearEvent: () => {
            // dispatch(clearEventReport());
            dispatch({type: 'CLEAR_EVENT'});
        },
        get_current_event: (eventId) => {
            dispatch(get_current_event(eventId));
        },
        loadObjects: () => {
            dispatch(loadObjectsObservationList());
        },
        loadEmployeesList: () => {
            dispatch(clearEmploeesState());
            dispatch(loadEmploeesList());
        },
    }),
)(withDataErrorIndication(Employees));
