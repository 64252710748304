import React from 'react';
import {withRoute} from 'react-router5';
import {startsWithSegment} from 'router5-helpers';

import routes from '../router/routes';
import NotFound from '../pages/NotFound';
import {PAGES_AVAILABLE, ROLES} from '../roles';
import {connect} from 'react-redux';

const findHomeFor = (role) => {
    return PAGES_AVAILABLE[role].length ? PAGES_AVAILABLE[role][0] : null;
}

const setLocation = (curLoc) => {
    try {
        window.history.pushState(null, null, curLoc);
        return;
    } catch(e) {}
    window.location.hash = '#' + curLoc;
}

const SwitchPages = ({ route, user }) => {
    if (!route) {
        return <NotFound />;
    }
    // if (user.role.includes(ROLES.OPERATOR) || user.role.includes(ROLES.HEAD)) {
    //     console.log('hello')
    // }
    const {params, name} = route;
    const testRoute = startsWithSegment(name);
    const matchingRoute = routes?.find(route => testRoute(route.name));
    const pagesForRole = PAGES_AVAILABLE[user.role] || [];
    if (!pagesForRole.includes(matchingRoute.name)) {
        if (!findHomeFor(user.role)) {
          return <NotFound/>;
        }
        const route = routes.find(route => route.name === findHomeFor(user.role));
        setLocation(route.path);
        return route.page(params) || <NotFound/>;
    }
    return matchingRoute.page(params);
};

export default withRoute(connect(
    state => ({
        user: state.user,
    })
)(SwitchPages));
