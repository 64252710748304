import { ROLES } from '../../roles';
import {ROUTES} from "../../router/routes";

const operator = [
  {title: 'Открыть реестр нарушений', page: 'Реестр нарушений', img: '/faq/operator1.png'},
  {title: 'Необходимо с помощью фильтра найти нарушение в реестре и открыть его нажатием кнопки мыши', page: 'Просмотр нарушения', img: '/faq/operator2.png'},
  {title: 'Необходимо зафиксировать нарушение, нажав на соответствующую кнопку', page: 'Фиксация нарушения', img: '/faq/operator3.png'}
];

const head = [
  {title: 'Перейдите на закладку "Все детектируемые события" и в фильтре установите помещение', page: 'Поиск нарушений в помещении', img: '/faq/head1.png'},
  {title: 'Перейдите на вкладку "Нарушение за период", установите даты на начало и конец прошлого месяца и нажмите кнопку "Сформировать PDF"', page: 'Получение отчета за период', img: '/faq/head2.png'}
];

const manager = [
  {title: 'Перейдите на вкладку "Помещения" и нажмите на кнопку "Создать". Заполните обязательные поля и нажмите на кнопку "Сохранить"', page: 'Создание помещения', img: '/faq/manager1.png'},
  {title: 'Выделите созданное в предыдущем шаге помещение и нажмите на панели вверху кнопку "Удалить"', page: 'Удаление помещения', img: '/faq/manager2.png'}
];

export default {
  [ROLES.OPERATOR]: {
    [ROUTES.CAMERAS]: { page: 'Видеопотоки камер', slides: operator},
    [ROUTES.CAMERA]: { page: 'Просмотр камеры', slides: operator},
    [ROUTES.VIOLATIONS]: { page: 'Реестр нарушений', slides: operator},
    [ROUTES.EVENTS]: { page: 'Все детектируемые события', slides: operator},
    [ROUTES.FRAMES]: { page: 'Изображения', slides: operator},
    [ROUTES.EMPLOYEES]: { page: 'Детектированные лица', slides: operator},
    [ROUTES.NOTIFICATIONS]: { page: 'Оповещения', slides: operator}
  },
  [ROLES.HEAD]: {
    [ROUTES.CAMERAS]: { page: 'Видеопотоки камер', slides: head},
    [ROUTES.CAMERA]: { page: 'Просмотр камеры', slides: head},
    [ROUTES.EVENTS]: { page: 'Все детектируемые события', slides: head},
    [ROUTES.EMPLOYEES]: { page: 'Детектированные лица', slides: head},
    [ROUTES.NOTIFICATIONS]: { page: 'Оповещения', slides: head},
    [ROUTES.REPORT_PERIOD]: { page: 'Нарушения за выбранный период', slides: head},
    [ROUTES.REPORT_CAMERA]: { page: 'Нарушения ТБ, зафиксированные камерой', slides: head},
    [ROUTES.REPORT_SCORES]: { page: 'Отчет "Нарушения с индексом вероятности, превышающим допустимое значение"', slides: head},
    [ROUTES.REPORT_CONFIRMED]: { page: 'Отчет "Нарушения ТБ, подтвержденные Оператором', slides: head}

  },
  [ROLES.ADMIN_SAFETY]:{
    [ROUTES.DECISION_MATRIX]: { page: 'Матрица параметров детектирования', slides: []},
    [ROUTES.WORK_PERMIT]: { page: 'Наряд допуск', slides: []},
    [ROUTES.SWITCHING_FORM]: { page: 'Бланк переключений', slides: []}
  },
  [ROLES.INFORMATION_MANAGER]: {
    [ROUTES.ROOMS_MANAGEMENT]: { page: 'Помещения', slides: manager},
    [ROUTES.CAMERAS_MANAGEMENT]: { page: 'Камеры', slides: manager},
    [ROUTES.THERMAL_CAMERAS_MANAGEMENT]: { page: 'Тепловизионные камеры', slides: manager},
    [ROUTES.BLOCKS_MANAGEMENT]: { page: 'Объекты', slides: manager},
    [ROUTES.WORKWEAR]: { page: 'Типы спецодежды', slides: manager},
    [ROUTES.PPE]: { page: 'Типы СИЗ', slides: manager}
  }
}
