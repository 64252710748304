import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

import {loadObjectsObservationList} from '../store/ObjectObservation';
import {clearEventReport, loadEventReport} from '../store/Events';
import {omitUndefinedAndNullFields} from '../utils/objects';
import EventList from './EventList';
import Modal from '../components/Modal';
import Event from '../components/Event';
import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from '../utils/constants';
import withDataErrorIndication from '../components/withDataErrorIndication';
import Spinner from "../components/Spinner";
import {cameraClearState, cameraFlatDetailedList} from "../store/Camera";
import RemotePagination from "./RemotePagination";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {customFilter, FILTER_TYPES, selectFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {apiDataToEventObj, cameraInObject, omitRooms, onlyRooms, roomsInObject} from "../utils/utils";
import {objectsListToOptions} from "../utils/inputs";
import RangeFilter from "../components/RangeFilter";
import {getDateNow} from "../utils/time";
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {userFio} from "../utils/userUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faFile, faPlus, faSyncAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {thermalCameraList} from "../store/ThermalCamera";
import { useRouter } from 'react-router5';
import {
    videoRecorderClearState,
    videoRecorderList,
} from '../store/VideoRecorder';
import { isEqual } from 'lodash';
import { get_current_event,  } from '../store/Event';

const filterStatus = {
    CONFIRMED: 'Нарушение',
    DENIED: 'Не нарушение',
    NO_STATUS: 'Не проверено',
};


const EventsPage = ({        defaultQuery, loadEvents, clearEvents, loadObjects, employees, loadEmployeesList, clearEvent,
    events, objects, isViolationsOnly, isPendingEvents, loadAllCameras, cameras = [], eventsTotal, isPendingObjects,
    thermalCameras = [], loadThermalCamerasData, videoRecorderList = [], loadAllVideoRecorder, eventId, get_current_event, current_event, current_event_isPending}) => {
    const route = useRouter()
    const [page, setPage] = useState(START_PAGE);
    const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
    const [filtersList, setfiltersList] = useState(null);
    const [event, setEvent] = useState(null);
    const [apiEvents, setApiEvents] = useState([])
    
    const [unitId, setUnitId] = useState(null);
    const [objectId, setObjectId] = useState(null);
  
    useEffect(() => {
        loadObjects();
        loadAllCameras();
        loadThermalCamerasData();
        loadEmployeesList();
        loadAllVideoRecorder();
        loadEvents(START_PAGE, PAGE_SIZE, defaultQuery);
    }, []);

    useEffect(() => {
        const FilteredData = Object.entries(events || []).filter(item => item[1].primary_objects.length !== 0).filter(item => item[1].primary_objects[0].secondary_objects.length !== 0).filter(item => item[1].object_observation !== null);
        const data = FilteredData.map((event) => apiDataToEventObj(event[1], objects.list));
        setApiEvents(data)
  
    }, [events])

    useEffect(() => {
        if (eventId) {
            get_current_event(eventId)
        }
        return () => {
            clearEvent()
        }
    },[eventId])

    useEffect(() => {
        if (current_event?.id) {
            setEvent(current_event)
        }
    },[current_event])

    const reloadQuery = () => {
        // if (filtersList !== null) {
            const filtersQuery = {
                ...(filtersList?.power_unit && {power_unit: filtersList.power_unit.filterVal}),
                ...(filtersList?.room && {object_id: filtersList.room.filterVal}),
                ...(filtersList?.camera_name && {cameraId: filtersList.camera_name.filterVal}),
                ...(filtersList?.date && {
                    dateBegin: filtersList?.date.filterVal.from,
                    dateEnd: filtersList?.date.filterVal.to
                }),
                ...(filtersList?.confirmation_status && {hasViolation: filtersList?.confirmation_status.filterVal}),
            };
            loadEvents(page, sizePerPage, {
                ...defaultQuery,
                ...omitUndefinedAndNullFields(filtersQuery)
            });
        // }
    }

    useEffect(() => {
        reloadQuery();
    }, [filtersList, sizePerPage, page]);

    if (isPendingObjects ) {
        return <Spinner/>;
    }

    const reload = () => {
        // loadEvents(page, sizePerPage, defaultQuery);
        // onTableChange("filter", {sizePerPage: sizePerPage, page: page, filters: filtersList});
        reloadQuery();
    };


    const onTableChange = (type, {sizePerPage, page, filters, sortField, sortOrder}) => {
        // clearEvents();
        if (type === 'filter') {
            if (!isEqual(filters,filtersList)) {
                setPage(1)
            }
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
        setfiltersList(filters);
    };

    const onCloseModal = () => {
        if (window.location.pathname.includes('violations')) {
            route.navigate('VIOLATIONS')
        } else {
            route.navigate('EVENTS')
        }
        clearEvent()
        setEvent(null);
    };

   

    //

    const getPersonFIO = (person_id, persons) => {
        for (const p of persons) {
            if (person_id === p.id) {
                return userFio(p);
            }
        }
    };


    const columns = [{
        text: '№',
        dataField: 'id',
        headerStyle: () => ({width: '59px'})
    }, {
        text: 'Блок',
        dataField: 'power_unit',
        headerClasses: 'table-block',
        filter: selectFilter({
            options: objectsListToOptions(omitRooms(objects.list)),
            placeholder: 'Все энергоблоки',
        }),
        headerStyle: () => ({width: '159px'}),
        // formatter: cell => objectsListToOptions[cell],
    }, {
        text: 'Помещение',
        dataField: 'room',
        filter: selectFilter({
            options: objectsListToOptions(unitId ?
                roomsInObject(unitId, onlyRooms(objects.list)) : onlyRooms(objects.list)
            ),
            placeholder: 'Все помещения'
        }),
        headerStyle: () => ({width: '159px'})
    }, {
        text: 'Камера',
        dataField: 'camera_name',
        filter: selectFilter({
            options: objectsListToOptions(objectId ?
                // cameraInObject(objectId, cameras) : cameras
                cameraInObject(objectId, [...cameras, ...thermalCameras, ...videoRecorderList]) : [...cameras, ...thermalCameras, ...videoRecorderList]
            ),
            placeholder: 'Все камеры'
        }),
        headerStyle: () => ({width: '159px'}),
    }, {
        text: 'Дата и время',
        dataField: 'date',
        headerStyle: () => ({width: '220px'}),
        headerClasses: 'table-date',
        filter: customFilter({
            type: FILTER_TYPES.DATE,
        }),
        filterRenderer: (onFilter, column) =>
            <RangeFilter
                onFilter={onFilter}
                labelFrom="с" labelTo="по"
                valueFrom={getDateNow(-DEFAULT_INTERVAL_HOURS)}
                valueTo={getDateNow()}
            />
    }, {
        text: 'Индекс',
        dataField: 'score',
        headerStyle: () => ({width: '79px'})
    }, {
        text: 'Работник',
        dataField: 'person',
        formatter: cell => getPersonFIO(cell, employees),
    }, {
        text: 'Найденное нарушение',
        dataField: 'type',
    }, {
        text: 'Статус',
        dataField: 'confirmation_status',
        headerStyle: () => ({width: '150px'}),
        formatter: cell => filterStatus[cell],
        ...(!isViolationsOnly && {
            filter: selectFilter({
                options: filterStatus,
                placeholder: 'Все',
            })
        })
    }]
        .map(col => ({
            ...col,
            editable: false,
            classes: 'px-0 py-0 table-cell',
        }));

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            // setEvent(row);
            if (window.location.pathname.includes('violations')) {
                route.navigate('VIOLATION', { eventId: row.id })
            } else {
                route.navigate('EVENT', { eventId: row.id })
            }
            
            // window.history.pushState(null, null, `/events/${row.id}`)
        }
    };

    const rowClass = (row, rowIndex) => {
        if (row.confirmation_status === "NO_STATUS") {
            return 'eventRow notChecked'
        }
        if (row.confirmation_status === "DENIED") {
            return 'eventRow noViolation'
        }
        if (row.confirmation_status === "CONFIRMED") {
            return 'eventRow violation'
        }
    };


    let tableRef;

    return (
        <>
            <div className="btn-wrapper with-other-btns">
                <div className="btn-wrapper-left">
                    <button className="table-control-btn btn-reload" onClick={reload}>
                        <FontAwesomeIcon icon={faSyncAlt} size="lg" color="#919191"/>
                        Обновить
                    </button>
                </div>
            </div>
            <RemotePagination
                data={apiEvents ? apiEvents : []}
                totalSize={eventsTotal}
                sizePerPage={sizePerPage}
                page={page}
                onSizePerPageChange={(...params) => {
                    const filters = tableRef.filterContext.currFilters;
                    onTableChange(null, {sizePerPage: params[0], page: params[1], filters});
                    // onTableChange(null, {sizePerPage: params[0], page: page, filters});
                }}
                onTableChange={(type, options) => {
                    if (type === 'filter') {
                        const object = options.filters.room;
                        setObjectId(object ? parseInt(object.filterVal) : null);
                        const unit = options.filters.power_unit;
                        setUnitId(unit ? parseInt(unit.filterVal) : null);
                    }
                    // onTableChange(type, options);
                    onTableChange(type, {sizePerPage: options.sizePerPage || sizePerPage, page: options.page, filters: options.filters});
                }}
            >
                <BootstrapTable
                    rowEvents={rowEvents}
                    remote={{filter: true, sort: true}}
                    ref={el => tableRef = el}
                    keyField="uniqKey"
                    data={apiEvents ? apiEvents : []}
                    columns={columns}
                    defaultSorted={[{dataField: 'date', order: 'asc'}]}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                    // onTableChange={onTableChange}
                    onTableChange={() => {}}
                    rowClasses={rowClass}
                    noDataIndication={() => isPendingEvents ? <Spinner/> : 'Данные отсутствуют'}
                />
            </RemotePagination>

            {event &&
            <Modal
                title={`Нарушение № ${event.id} (Камера - ${event.camera_name})`}
                isOpen
                className="big_modal"
                showPrintButton={true}
                onClose={onCloseModal}>
                <Event 
                       reload={() => reload()}
                       employees={employees}
                      
                />
            </Modal>}
        </>
    );
};

export default connect(
    state => ({
        user: state.user,
        videoRecorderList: state.videoRecorder.list || [],
        event: state.event.event,
        current_event: state.event?.current_event || {},
        current_event_isPending: state.event?.current_event_isPending || false,
        events: state?.events?.isPending ? [] : state.events.list?.events,
        eventsTotal: state.events.list?.total_events,
        cameras: state.camera.flatList || [],
        thermalCameras: state.thermalCamera.list || [],
        objects: state.object,
        employees: state.employees.list,
        isPendingEvents: state.events.isPending,
        isPendingObjects: state.videoRecorder.isListPending || state.object.isListPending || state.camera.isListPending || state.thermalCamera.isListPending || state.employees.isListPending,
        isError: state.events.isError,
    }),
    dispatch => ({
        loadEvents: (pageNumber, pageSize, query) => {
            // dispatch(clearEventReport());
            dispatch(loadEventReport(query, pageNumber, pageSize));
        },
        clearEvent: () => {
            // dispatch(clearEventReport());
            dispatch({type: 'CLEAR_EVENT'});
        },
        get_current_event: (eventId) => {
            dispatch(get_current_event(eventId));
        },
        clearEvents: () => {
            dispatch(clearEventReport());
        },
        loadAllVideoRecorder: () => {
            dispatch(videoRecorderClearState());
            dispatch(videoRecorderList());
        },
        loadObjects: () => {
            dispatch(loadObjectsObservationList());
        },
        loadAllCameras: () => {
            // dispatch(cameraClearState());
            dispatch(cameraFlatDetailedList());
        },
        loadThermalCamerasData: () => {
            dispatch(thermalCameraList());
        },
        loadEmployeesList: () => {
            dispatch(clearEmploeesState());
            dispatch(loadEmploeesList());
        },
    }),
)(withDataErrorIndication(EventsPage));
