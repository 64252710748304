import React from 'react';

import Objects from '../pages/Objects';
import Cameras from '../pages/Cameras';
import Camera from '../pages/Camera';
import Events from '../pages/Events';
import RejectedEvents from '../pages/RejectedEvents';
import Violations from '../pages/Violations';
import Frames from '../pages/Frames';
import Notifications from '../pages/Notifications';
import EventsReport from '../pages/EventsReport';
import ExternalPage from '../pages/ExternalPage';
import Zones from '../pages/Zones';
import ModelsList from '../pages/ModelsList';
import CamerasManagement from '../pages/CamerasManagement';
import ThermalCamerasManagement from '../pages/ThermalCamerasManagement';
import ObjectsManagement from '../pages/ObjectsManagement';
import Workwear from '../pages/Workwear';
import PPEs from '../pages/PPEs';
import DecisionMatrix from '../pages/DecisionMatrix';
import UsersManagement from '../pages/UsersManagement';
import EmployeesManagement from "../pages/EmployeesManagement";
import CleanSettings from "../pages/CleanSettings";
import UserLogs from "../pages/UserLogsPage";
import Monitoring from "../pages/Monitoring";
import Employees from "../pages/Employees";
import SubdivisionManagement from "../pages/SubdivisionManagement";
import RoomWork from "../pages/RoomWork";
import WorkPermit from "../pages/WorkPermit";
import SwithingForm from "../pages/SwithingForm";
import CupboardMenagement from "../pages/CupboardMenagement";
import CupboardLayout from "../pages/CupboardLayout";
import RoomNotOur from "../pages/RoomNotOur";
import RoomNotOurMenagment from "../pages/RoomNotOurMenagment";
import Statistic from "../pages/Statistic";
import VideoRecorderManagement from '../pages/VideoRecorderManagement';
import VideoRecorderUsers from '../pages/VideoRecorderUsers';
import VideoRecorderUpVideo from '../pages/VideoRecorderUpVideo';
import NotificationDevices from '../pages/NotificationDevices';
import NotificationDevicesMapping from '../pages/NotificationDevicesMapping';

export const ROUTES = {
    OBJECTS: 'OBJECTS',
    CAMERAS: 'CAMERAS',
    CAMERA: 'CAMERA_PLAYER',
    EVENTS: 'EVENTS',
    REJECTED_EVENTS: 'REJECTED_EVENTS',
    EVENT: 'EVENT',
    VIOLATIONS: 'VIOLATIONS',
    VIOLATION: 'VIOLATION',
    FRAMES: 'FRAMES',
    FRAME: 'FRAME',
    EMPLOYEES: 'EMPLOYEES',
    EMPLOYEE: 'EMPLOYEE',
    NOTIFICATIONS: 'NOTIFICATIONS',
    NOTIFICATION: 'NOTIFICATION',
    REPORT_PERIOD: 'REPORT_PERIOD',
    REPORT_CAMERA: 'REPORT_CAMERA',
    REPORT_SCORES: 'REPORT_SCORES',
    REPORT_CONFIRMED: 'REPORT_CONFIRMED',
    REPORT_USER: 'REPORT_USER',
    SETS: 'SETS',
    TENSORBOARD: 'TENSORBOARD',
    QUALITY: 'QUALITY',
    SOURCES: 'SOURCES',
    ANNOTATION: 'ANNOTATION',
    ZONES: 'ZONES',
    CATEGORIES: 'CATEGORIES',
    MODELS: 'MODELS',
    ROOMS_MANAGEMENT:  'ROOMS_MANAGEMENT',
    CAMERAS_MANAGEMENT: 'CAMERAS_MANAGEMENT',
    THERMAL_CAMERAS_MANAGEMENT: 'THERMAL_CAMERAS_MANAGEMENT',
    BLOCKS_MANAGEMENT: 'BLOCKS_MANAGEMENT',
    WORKWEAR: 'WORKWEAR',
    PPE: 'PPE',
    DECISION_MATRIX: 'DECISION_MATRIX',
    USERS_MANAGEMENT: 'USERS_MANAGEMENT',
    EMPLOYEES_MANAGEMENT: 'EMPLOYEES_MANAGEMENT',
    CLEAN_SETTINGS: 'CLEAN_SETTINGS',
    LOGS: 'LOGS',
    MONITORING_SYSTEM: 'MONITORING_SYSTEM',
    MONITORING_SERVER: 'MONITORING_SERVER',
    SUBDIVISION_MANAGEMENT: 'SUBDIVISION_MANAGEMENT',
    ROOM_WORK: 'ROOM_WORK',
    WORK_PERMIT: 'WORK_PERMIT',
    SWITCHING_FORM: 'SWITCHING_FORM',
    CUPBOARD_OUR_MANAGEMENT: 'CUPBOARD_OUR_MANAGEMENT',
    CUPBOARD_NOT_OUR_MANAGEMENT: 'CUPBOARD_NOT_OUR_MANAGEMENT',
    CUPBOARD_LAYOUT: 'CUPBOARD_LAYOUT',
    ROOM_NOT_OUR: 'ROOM_NOT_OUR',
    ROOM_NOT_OUR_MANAGEMENT: 'ROOM_NOT_OUR_MANAGEMENT',
    STATISTIC: 'STATISTIC',
    VIDEO_RECORDER_MANAGEMENT: 'VIDEO_RECORDER_MANAGEMENT',
    VIDEO_RECORDER_USERS_LIST: 'VIDEO_RECORDER_USERS_LIST',
    VIDEO_RECORDER_UP_VIDEO: 'VIDEO_RECORDER_UP_VIDEO',
    NOTIFICATION_DEVICES: 'NOTIFICATION_DEVICES',
    NOTIFICATION_DEVICES_MAPPING: 'NOTIFICATION_DEVICES_MAPPING',
};

export default [{
    path: '/objects',
    name: ROUTES.OBJECTS,
    page: (params) => <Objects params={params}/>
}, {
    path: '/cameras',
    name: ROUTES.CAMERAS,
    page: (params) => <Cameras params={params}/>
}, {
    path: '/object/:objId/cameras/:type/:camId',
    name: ROUTES.CAMERA,
    page: (params) => <Camera params={params}/>
}, 
{
    path: '/events',
    name: ROUTES.EVENTS,
    page: (params) => <Events params={params} />
},
{
    path: '/events/:eventId',
    name: ROUTES.EVENT,
    page: (params) => <Events params={params} />
}
, {
    path: '/rejected-events',
    name: ROUTES.REJECTED_EVENTS,
    page: (params) => <RejectedEvents params={params}/>
},  {
    path: '/violations',
    name: ROUTES.VIOLATIONS,
    page: (params) => <Violations params={params}/>
},
{
    path: '/violations/:eventId',
    name: ROUTES.VIOLATION,
    page: (params) => <Violations params={params} />
}, {
    path: '/frames',
    name: ROUTES.FRAMES,
    page: (params) => <Frames params={params}/>
},{
    path: '/frames/:eventId',
    name: ROUTES.FRAME,
    page: (params) => <Frames params={params}/>
}, {
    path: '/employees',
    name: ROUTES.EMPLOYEES,
    page: (params) => <Employees params={params}/>
}, {
    path: '/employees/:eventId',
    name: ROUTES.EMPLOYEE,
    page: (params) => <Employees params={params}/>
},{
    path: '/notification',
    name: ROUTES.NOTIFICATIONS,
    page: (params) => <Notifications params={params}/>
}, {
    path: '/notification/:eventId',
    name: ROUTES.NOTIFICATION,
    page: (params) => <Notifications params={params}/>
}, {
    path: '/report-period',
    name: ROUTES.REPORT_PERIOD,
    page: (params) => <EventsReport params={params} type="period"/>
}, {
    path: '/report-camera',
    name: ROUTES.REPORT_CAMERA,
    page: (params) => <EventsReport params={params} type="camera"/>
}, {
    path: '/report-scores',
    name: ROUTES.REPORT_SCORES,
    page: (params) => <EventsReport params={params} type="scores"/>
}, {
    path: '/report-confirmed',
    name: ROUTES.REPORT_CONFIRMED,
    page: (params) => <EventsReport params={params} type="confirmed"/>
}, {
    path: '/report-user-accessed',
    name: ROUTES.REPORT_USER,
    page: (params) => <EventsReport params={params} type="user"/>
}, {
    path: '/sets',
    name: ROUTES.SETS,
    page: (params) => <ExternalPage
        params={params}
        title='Список наборов тренировки'
        page='/learning/train/train_test_list_editor'
    />
}, {
    path: '/tensorboard',
    name: ROUTES.TENSORBOARD,
    page: (params) => <ExternalPage
        params={params}
        title='Обучение нейронной сети'
        page='/machine-lerning'
    />
}, {
    path: '/quality',
    name: ROUTES.QUALITY,
    page: (params) => <ExternalPage
        params={params}
        title='Проверка и утверждение модели'
        page='/learning/train/quality'
    />
}, {
    path: '/sources',
    name: ROUTES.SOURCES,
    page: (params) => <ExternalPage
        params={params}
        title='Список источников разметки'
        page='/learning/train/index'
    />
}, {
    path: '/annotation',
    name: ROUTES.ANNOTATION,
    page: (params) => <ExternalPage
        params={params}
        title='Разметка'
        page='/learning/anotaion-tool/task_table/'
    />
}, {
    path: '/decision-matrix',
    name: ROUTES.DECISION_MATRIX,
    page: (params) => <DecisionMatrix params={params}/>
}, {
    path: '/room-work',
    name: ROUTES.ROOM_WORK,
    page: (params) => <RoomWork params={params}/>
}, {
    path: '/work-permit',
    name: ROUTES.WORK_PERMIT,
    page: (params) => <WorkPermit params={params}/>
}, {
    path: '/switching-form',
    name: ROUTES.SWITCHING_FORM,
    page: (params) => <SwithingForm params={params}/>
}, {
    path: '/zones',
    name: ROUTES.ZONES,
    page: (params) => <Zones params={params}/>
}, {
    path: '/categories',
    name: ROUTES.CATEGORIES,
    page: (params) => <ExternalPage
        params={params}
        title='Редактор категорий'
        page='/learning/train/category_config_editor'
    />
}, {
    path: '/models',
    name: ROUTES.MODELS,
    page: (params) => <ModelsList params={params}/>
}, {
    path: '/rooms-management',
    name: ROUTES.ROOMS_MANAGEMENT,
    page: (params) => <ObjectsManagement params={params} type="rooms"/>
}, {
    path: '/cameras-management',
    name: ROUTES.CAMERAS_MANAGEMENT,
    page: (params) => <CamerasManagement params={params}/>
}, {
    path: '/thermal-cameras-management',
    name: ROUTES.THERMAL_CAMERAS_MANAGEMENT,
    page: (params) => <ThermalCamerasManagement params={params}/>
}, {
    path: '/blocks-management',
    name: ROUTES.BLOCKS_MANAGEMENT,
    page: (params) => <ObjectsManagement params={params} type="blocks"/>
}, {
    path: '/workwear',
    name: ROUTES.WORKWEAR,
    page: (params) => <Workwear params={params}/>
}, {
    path: '/ppes',
    name: ROUTES.PPE,
    page: (params) => <PPEs params={params}/>
}, {
    path: '/users-management',
    name: ROUTES.USERS_MANAGEMENT,
    page: (params) => <UsersManagement params={params}/>
}, {
    path: '/employees-management',
    name: ROUTES.EMPLOYEES_MANAGEMENT,
    page: (params) => <EmployeesManagement params={params}/>
}, {
    path: '/subdivision-management',
    name: ROUTES.SUBDIVISION_MANAGEMENT,
    page: (params) => <SubdivisionManagement params={params}/>
}, {
    path: '/clean-settings',
    name: ROUTES.CLEAN_SETTINGS,
    page: (params) => <CleanSettings params={params}/>
}, {
    path: '/logs',
    name: ROUTES.LOGS,
    page: (params) => <UserLogs params={params}/>
}, {
    path: '/monitoring-system',
    name: ROUTES.MONITORING_SYSTEM,
    page: (params) => <Monitoring
        params={params}
        url="/grafana/d/hq1WGAfGk/domain-statistics?orgId=1"
        title="Мониторинг системы"
    />
}, {
    path: '/monitoring-server',
    name: ROUTES.MONITORING_SERVER,
    page: (params) => <Monitoring
        params={params}
        url="/grafana/d/rYdddlPWk/statistika-servera?orgId=1&refresh=1m"
        title="Мониторинг сервера"
    />
}, {
    path: '/cupboard-our-management',
    name: ROUTES.CUPBOARD_OUR_MANAGEMENT,
    page: (params) => <CupboardMenagement
        our={true}
        params={params}/>
}, {
    path: '/cupboard-not-our-management',
    name: ROUTES.CUPBOARD_NOT_OUR_MANAGEMENT,
    page: (params) => <CupboardMenagement
        our={false}
        params={params}/>
}, {
    path: '/cupboard-layout',
    name: ROUTES.CUPBOARD_LAYOUT,
    page: (params) => <CupboardLayout params={params}/>
}, {
    path: '/room-not-our',
    name: ROUTES.ROOM_NOT_OUR,
    page: (params) => <RoomNotOur params={params}/>
}, {
    path: '/room-not-our-management',
    name: ROUTES.ROOM_NOT_OUR_MANAGEMENT,
    page: (params) => <RoomNotOurMenagment params={params}/>
}, {
    path: '/statistic',
    name: ROUTES.STATISTIC,
    page: (params) => <Statistic params={params}/>
}, {
    path: '/video-recorder-management',
    name: ROUTES.VIDEO_RECORDER_MANAGEMENT,
    page: (params) => <VideoRecorderManagement params={params} />
}, {
    path: '/video-recorder-users-list',
    name: ROUTES.VIDEO_RECORDER_USERS_LIST,
    page: (params) => <VideoRecorderUsers params={params} />
}, {
    path: '/video-recorder-up-video',
    name: ROUTES.VIDEO_RECORDER_UP_VIDEO,
    page: (params) => <VideoRecorderUpVideo params={params} />
},{
    path: '/notification-devices',
    name: ROUTES.NOTIFICATION_DEVICES,
    page: (params) => <NotificationDevices params={params} />
},{
    path: '/notification-devices-mapping',
    name: ROUTES.NOTIFICATION_DEVICES_MAPPING,
    page: (params) => <NotificationDevicesMapping params={params} />
},

]
