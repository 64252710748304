import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFolderOpen} from '@fortawesome/free-solid-svg-icons';

import {switchRole} from '../store/Auth';
import {ROLES} from '../roles';
import {rolesOptionsByName, rolesOptionsByKey} from '../utils/inputs';

const availableRoles = Object.keys(ROLES);

const RoleSwitcher = ({user, switchRole}) => {
    const role = Object.keys(ROLES).find(key => ROLES[key] === user.roles[0]);

    // useEffect(() => {
    //     if (user.role.includes(ROLES.OPERATOR) || user.role.includes(ROLES.HEAD))  {
    //         if (window.location.pathname.includes('event')) {
    //             console.log('hello111')
    //         }
    //     }
    // },[])


    return (
        <div className="large-offset-0 large-5 roles">
            <FontAwesomeIcon
                icon={faFolderOpen}
                alt="Текущая роль пользователя системы"
                color="white"
            />
            <select
                defaultValue={user.role}
                onChange={(e) => {
                    switchRole(e.target.value);
                }}>
                {role !== "ADMIN" ?
                    rolesOptionsByName(user.roles)
                    :
                    rolesOptionsByKey(availableRoles)
                }
            </select>
        </div>
    )
};

export default connect(
    state => ({
        user: state.user,
    }),
    dispatch => ({
        switchRole: (role) => {
            dispatch(switchRole(role));
        }
    })
)(RoleSwitcher);
